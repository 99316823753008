<template>
<div>
  <div class="inquiry-form  mo:mb-12">
    <div class="inquiry-form__inner">
      <template v-if="state == 'form' || state == 'submitting' || state == 'error'">
        <div v-if="allowBookingRequest" class="flex mo:flex-col mb-10">
          <div class="radio mo:mb-4">
            <input type="radio" id="question" :value="INQUIRY_TYPE.QUESTION" v-model="inquiryMeta.type">
            <label for="question" class="mr-8">
              <span>{{ labels.typeQuestion }}</span>
            </label>
          </div>
          <div class="radio">
            <input type="radio" id="offer" :value="INQUIRY_TYPE.OFFER" v-model="inquiryMeta.type">
            <label for="offer">
              <span>{{ labels.typeQuote }}</span>
            </label>
          </div>
        </div>
        <form novalidate @submit.prevent="submit">
          <SlideUpDown :active="allowBookingRequest && inquiryMeta.type == INQUIRY_TYPE.OFFER" :duration="300">

          <fieldset class="pb-8">
            <div class="inquiry-form__vehicles mb-6">
              <legend class="mb-4">{{ labels.yourSelection }}</legend>
              <template v-if="isVehicleSelected">
                <img class="h-32 -mb-3"
                     v-if="selectedVehicle.imageSrcSet"
                     :srcset="selectedVehicle.imageSrcSet"
                     :src="selectedVehicle.imageSrc"
                     :alt="selectedVehicle.title">
                <span class="inquiry-form__vehicle-title whitespace-no-wrap font-medium text-4xl mo:hidden">
                    {{ vehicleTitle(selectedVehicle) }}
                </span>
              </template>
              <div class="mb-8" v-else>
                <p class="italic" :class="{ 'text-red-500': $v.selectedVehicles.$error }">{{ labels.noSelection }}</p>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-10">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <label class="block mb-1" for="rentalStartDate">{{ labels.startDate }}*</label>
                <div class="relative" :class="{ 'error': $v.inquiry.rental.startDate.$error }">
                  <flat-pickr v-model="$v.inquiry.rental.startDate.$model" :placeholder="earliestStartDate" :config="flatPickrConfig.rentalDate" @on-change="onRentalDateChange" />
                </div>
                <template v-if="$v.inquiry.rental.startDate.$error">
                  <span class="inquiry-form__error" v-if="!$v.inquiry.rental.startDate.required">{{ labels.notEmpty }}</span>
                </template>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <template v-if="hasIndividualTerm">
                  <label class="block mb-1" for="rentalEndDate">{{ labels.endDate }}</label>
                  <input class="opacity-50" type="text" name="rentalEndDate" :value="fixedEndDate" readonly />
                </template>
                <template v-else>
                  <div class="relative" :class="{ 'error': $v.inquiry.rental.endDate.$error }">
                    <label class="block mb-1" for="rentalEndDate">{{ labels.endDate }}*</label>
                    <flat-pickr v-model="$v.inquiry.rental.endDate.$model" :placeholder="earliestEndDate" :config="flatPickrConfig.rentalEndDate" ref="rentalEndDate" />
                  </div>
                </template>
                <template v-if="$v.inquiry.rental.endDate.$error">
                  <span class="inquiry-form__error" v-if="!$v.inquiry.rental.endDate.required">{{ labels.notEmpty }}</span>
                </template>
              </div>
            </div>
          </fieldset>
          </SlideUpDown>

          <fieldset class="mb-8">
            <legend class="mb-4">
              {{ inquiryMeta.isDealer ? labels.formClientHeadingForDealer : labels.formClientHeading }}
            </legend>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <TextField
                  name="firstName" :label="labels.firstName" :placeholder="labels.firstName_p"
                  :validation="$v.inquiry.client.firstName" :messages="errorMessages"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <TextField
                  name="lastName" :label="labels.lastName" :placeholder="labels.lastName_p"
                  :validation="$v.inquiry.client.lastName" :messages="errorMessages"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <TextField
                  name="email" :label="labels.email" :placeholder="labels.email_p"
                  :validation="$v.inquiry.client.email" :messages="errorMessages"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <TextField
                  name="phone" :label="labels.phone" :placeholder="labels.phone_p"
                  :validation="$v.inquiry.client.phone" :messages="errorMessages"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <TextField
                  name="company" :label="labels.company" :placeholder="labels.company_p"
                  :validation="$v.inquiry.client.company" :messages="errorMessages"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <TextField
                  name="address" :label="labels.address" :placeholder="labels.address_p"
                  :validation="$v.inquiry.client.address" :messages="errorMessages"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <TextField
                  name="postalCode" :label="labels.postalCode" :placeholder="labels.postalCode_p"
                  :validation="$v.inquiry.client.postalCode" :messages="errorMessages"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <TextField
                  name="city" :label="labels.city" :placeholder="labels.city_p"
                  :validation="$v.inquiry.client.city" :messages="errorMessages"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 pb-6">
              <div class="w-full px-3">
                <label class="block mb-1" for="contactLanguage">{{ labels.formContactLanguage }}*</label>
                <multiselect
                  v-model="$v.inquiry.contactLanguage.$model"
                  track-by="id"
                  label="label"
                  :option-height="54"
                  :max-height="270"
                  :options="contactLanguageOptions"
                  :searchable="false"
                  :show-labels="false"
                />
              </div>
            </div>

            <SlideUpDown :active="inquiryMeta.type == INQUIRY_TYPE.QUESTION" :duration="300">
              <div class="w-full pb-6">
                <label class="block mb-1" for="message">{{ labels.yourQuestion }}*</label>
                <textarea class="block w-full " :placeholder="labels.yourQuestion_p" :class="{ 'invalid': $v.inquiry.message.$error, 'valid': !$v.inquiry.message.$error }" rows="8" id="message" type="text" v-model="$v.inquiry.message.$model" :tabindex="inquiryMeta.type == INQUIRY_TYPE.QUESTION ? 0 : -1" />
                <template v-if="$v.inquiry.message.$error">
                  <span class="inquiry-form__error" v-if="!$v.inquiry.message.required">{{ labels.notEmpty }}</span>
                </template>
              </div>
            </SlideUpDown>
          </fieldset>

          <fieldset v-if="inquiryMeta.isDealer" class="mb-2 sm:mb-8">
            <legend class="mb-8">{{ labels.formDealerHeading }}</legend>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <TextField
                  name="dealerCompany" :label="labels.company" :placeholder="labels.company_p"
                  :validation="$v.inquiry.dealer.company" :messages="errorMessages" :readonly="!!inquiry.dealer.company"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <TextField
                  name="dealerFirstName" :label="labels.firstName" :placeholder="labels.firstName_p"
                  :validation="$v.inquiry.dealer.firstName" :messages="errorMessages"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <TextField
                  name="dealerLastName" :label="labels.lastName" :placeholder="labels.lastName_p"
                  :validation="$v.inquiry.dealer.lastName" :messages="errorMessages"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <TextField
                  name="dealerEmail" :label="labels.email" :placeholder="labels.email_p"
                  :validation="$v.inquiry.dealer.email" :messages="errorMessages"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <TextField
                  name="dealerPhone" :label="labels.phone" :placeholder="labels.phone_p"
                  :validation="$v.inquiry.dealer.phone" :messages="errorMessages"
                />
              </div>
            </div>
          </fieldset>

          <fieldset class="mb-8" v-if="inquiryMeta.type === INQUIRY_TYPE.OFFER">
            <legend class="mb-8">{{ labels.formHandoverHeading }}</legend>

            <div class="flex mo:flex-col mb-4">
              <div class="radio mr-8 mo:mb-4">
                <input
                  type="radio" id="handoverCollection" v-model="inquiryMeta.handoverType" :value="HANDOVER_TYPE.COLLECTION">
                <label for="handoverCollection">
                  <span>{{ labels.rentalCollection }}</span>
                </label>
              </div>
              <div class="radio mr-8 mo:mb-4" v-if="offerDealerDelivery">
                <input type="radio" id="handoverDealerDelivery" v-model="inquiryMeta.handoverType" :value="HANDOVER_TYPE.DEALER_DELIVERY">
                <label for="handoverDealerDelivery">
                  <span>{{ labels.rentalDeliveryDealer }}</span>
                </label>
              </div>
              <div class="radio">
                <input type="radio" id="handoverHomeDelivery" v-model="inquiryMeta.handoverType" :value="HANDOVER_TYPE.HOME_DELIVERY">
                <label for="handoverHomeDelivery">
                  <span>{{ labels.rentalDeliveryHome }}</span>
                </label>
              </div>
            </div>

            <div class="inquiry-form__info-panel  flex mb-4">
              <div class="w-4 h-4 mr-3 mt-1 flex-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.41 78.41"><path d="M26.09 37.11c-.55.66-1.03 1.42-.67 1.95.57.85 2.27-1.04 2.27-1.04 1.45-1.41 2.89-3.15 4.6-4.11.87-.49 2.62-1.13 2.13.6-.76 2.65-6.16 15.35-6.16 15.35s-3.6 7.01-1.71 10.52c.93 1.72 3.26 1.7 4.94 1.48 4.42-.58 8.36-3.1 11.36-6.31 2.65-2.84 3.32-4.26 2.46-4.74-.99-.55-2.42 1.43-3.02 1.97-.17.15-.96.77-1.12 1.01-.83.75-2.08 1.97-3.29 1.86-.96-.09-.99-1.03-.82-1.8.66-3.03 2.84-7.86 2.84-7.86l3.51-10.14s2.65-5.49-1.89-6.44c0 0-3.98-1.04-7.96 1.04-1.38.73-2.62 1.71-3.75 2.78-.52.49-1.02 1.01-1.5 1.54-.4.42-1.86 1.9-2.22 2.34z" fill="currentColor" /><ellipse transform="rotate(-5.601 42.83 21.335)" cx="42.85" cy="21.34" rx="5.49" ry="5.12" fill="currentColor"/><circle cx="39.22" cy="39.03" r="32.9" fill="none" stroke="currentColor" stroke-width="3" stroke-miterlimit="10"/></svg>
              </div>

              <div class="italic max-w-lg">
                <template v-if="inquiryMeta.handoverType === HANDOVER_TYPE.COLLECTION">
                  <p>{{ labels.rentalCollectionNote }}</p>
                  <p v-if="inquiry.rental.handoverStation && inquiry.rental.handoverStation.surcharge">
                    <strong class="font-medium">{{ stationSurchargeNote }}</strong>
                  </p>
                </template>
                <p v-else-if="inquiryMeta.handoverType === HANDOVER_TYPE.DEALER_DELIVERY">
                  {{ labels.rentalDeliveryDealerNote }}
                </p>
                <p v-else-if="inquiryMeta.handoverType === HANDOVER_TYPE.HOME_DELIVERY">
                  {{ labels.rentalDeliveryHomeNote }}
                </p>
              </div>
            </div>

            <SlideUpDown :active="inquiryMeta.handoverType !== HANDOVER_TYPE.HOME_DELIVERY" :duration="300">
            <div class="flex flex-wrap -mx-3 pb-6">
              <div class="w-full px-3">
                <multiselect
                  v-model="$v.inquiry.rental.handoverStation.$model"
                  track-by="id"
                  :options="stationsOptions"
                  :custom-label="stationLabel"
                  :option-height="54"
                  :max-height="270"
                  :show-labels="false"
                  :disabled="stationsOptions.length === 1"
                  :placeholder="
                    inquiryMeta.handoverType === HANDOVER_TYPE.DEALER_DELIVERY
                      ? labels.rentalStationDealerPlaceholder
                      : labels.rentalStationHertzPlaceholder
                  "
                  :class="{ 'invalid': $v.inquiry.rental.handoverStation.$error, 'valid': !$v.inquiry.rental.handoverStation.$error }">
                  <template slot="noResult">{{ labels.rentalStationSearchNoResults }}</template>
                </multiselect>
                <template v-if="$v.inquiry.rental.handoverStation.$error">
                  <span class="inquiry-form__error" v-if="!$v.inquiry.rental.handoverStation.required">{{ labels.notEmpty }}</span>
                </template>
              </div>
            </div>
            </SlideUpDown>

            <SlideUpDown :active="inquiryMeta.handoverType === HANDOVER_TYPE.HOME_DELIVERY" :duration="300">
            <div class="checkbox pb-8  sm:pl-6">
              <input type="checkbox" v-model="inquiryMeta.isDeliveryWithDifferentAddress" id="deliveryDifferentAddress">
              <label for="deliveryDifferentAddress">
                {{ labels.rentalDeliveryDifferentAddress }}
              </label>
            </div>
            </SlideUpDown>

            <SlideUpDown :active="inquiryMeta.handoverType === HANDOVER_TYPE.HOME_DELIVERY && inquiryMeta.isDeliveryWithDifferentAddress">
            <fieldset>
              <legend class="mb-4">{{ labels.formDeliveryAddressHeading }}</legend>
              <div class="flex flex-wrap -mx-3 pb-6">
                <div class="w-full px-3">
                  <TextField
                    name="deliveryAddress" :label="labels.address" :placeholder="labels.address_p"
                    :validation="$v.inquiry.rental.delivery.address" :messages="errorMessages"
                  />
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mo:mb-6">
                  <TextField
                    name="deliveryPostalCode" :label="labels.postalCode" :placeholder="labels.postalCode_p"
                    :validation="$v.inquiry.rental.delivery.postalCode" :messages="errorMessages"
                  />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <TextField
                    name="deliveryCity" :label="labels.city" :placeholder="labels.city_p"
                    :validation="$v.inquiry.rental.delivery.city" :messages="errorMessages"
                  />
                </div>
              </div>
            </fieldset>
            </SlideUpDown>
          </fieldset>

          <fieldset class="mb-8">
            <legend class="mb-8">{{ labels.dateHeadline }}</legend>

            <div class="checkbox mb-6  sm:pl-6">
              <input type="checkbox" id="isCallRequested" v-model="inquiryMeta.isCallRequested">
              <label for="isCallRequested" class="mr-8">
                <span>{{ labels.formCallMe }}</span>
              </label>
            </div>

            <SlideUpDown :active="inquiryMeta.isCallRequested" :duration="300">
            <div class="flex flex-wrap -mx-3 pb-6">
              <div class="w-full md:w-1/2 px-3 mo:mb-6">
                <label class="block mb-1" for="contactDate">{{ labels.preferredDate }}</label>
                <div>
                  <flat-pickr v-model="inquiry.contactDate" :config="flatPickrConfig.contactDate" @on-change="onContactDateChange" />
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block mb-1" for="contactTime">{{ labels.preferredTime }}</label>
                <div>
                  <flat-pickr v-model="inquiry.contactTime" :config="flatPickrConfig.contactTime" />
                </div>
              </div>
            </div>
            </SlideUpDown>
          </fieldset>

          <fieldset class="mb-3 sm:mb-12">
            <legend class="mb-8">{{ labels.formConsentHeading }}</legend>

            <div class="flex flex-wrap -mx-3 mb-8  sm:pl-6">
              <div class="w-full px-3 mo:mb-6">
                <div class="checkbox mb-4">
                  <input type="checkbox" id="newsletterHerold" v-model="inquiry.newsletterHerold">
                  <label for="newsletterHerold" class="mr-8">
                    <span>{{ labels.formNewsletterHerold }}</span>
                  </label>
                </div>
                <div class="checkbox mb-4" v-if="labels.formNewsletterBrand">
                  <input type="checkbox" id="newsletterBrand" v-model="inquiry.newsletterBrand">
                  <label for="newsletterBrand" class="mr-8">
                    <span>{{ labels.formNewsletterBrand }}</span>
                  </label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" id="termsAccepted" v-model="$v.inquiry.termsAccepted.$model">
                  <label for="termsAccepted" class="mr-8">
                    <span v-html="labels.termsAccepted" />
                  </label>
                  <template v-if="$v.inquiry.termsAccepted.$error">
                    <span class="inquiry-form__error" v-if="!$v.inquiry.termsAccepted.sameAs">{{ labels.mustAcceptTerms }}</span>
                  </template>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="flex items-center">
            <button type="submit" class="inquiry-form__submit"
                    :disabled="state == 'submitting'">
              <IconMail class="inline w-8 mr-3 fill-current stroke-current" /> {{ labels.submit }}
            </button>
            <span v-if="state == 'submitting'" class="ml-8 spinner" />
          </div>
          <SlideUpDown :active="state === 'error'" :duration="100">
          <fieldset class="pt-8">
            <p class="text-red-500 italic">We apologize, your inquiry could not be submitted.<br>Please try again later.</p>
          </fieldset>
          </SlideUpDown>

        </form>
      </template>
      <template v-if="state == 'thanks'">
        <slot />
      </template>
    </div>
  </div>
</div>
</template>

<script>
const ACTION_URI = '/actions/inquiry-module/inquiry/submit'
const LANGUAGE = document.documentElement.getAttribute('lang').substring(0, 2)

import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
import { required, email, sameAs, helpers } from 'vuelidate/lib/validators'
import IconMail from '@/assets/svg/icons/mail.svg?inline'

import dayjs from 'dayjs'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isoWeek from 'dayjs/plugin/isoWeek'

import FlatPickr from 'vue-flatpickr-component'
import en from 'flatpickr/dist/l10n/default'
import {French as fr} from 'flatpickr/dist/l10n/fr'
import {Italian as it} from 'flatpickr/dist/l10n/it'
import {German as de} from 'flatpickr/dist/l10n/de'
import { getValidations } from '../validations'
import tracking from 'hertz-tracking'
import { INQUIRY_TYPE, HANDOVER_TYPE } from '../enums'
import TextField from './fields/TextField.vue'
import 'flatpickr/dist/flatpickr.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const FlatPickrLanguages = {en, fr, it, de}
const MIN_DEFAULT_RENTAL_PERIOD = 30

dayjs.extend(isTomorrow)
dayjs.extend(isoWeek)

export default {
  mixins: [validationMixin],
  validations() {
    return getValidations(this.inquiryMeta)
  },
  props: {
    page: String,
    labels: Object,
    cdp: String,
    group: String,
    dealerCompany: String,
    discountCode: String,
    referralCode: String,
    language: String,
    trackingEventName: String,
    allowMultipleVehicles: {
      type: Boolean,
      default: false
    },
    offerDealerDelivery: {
      type: Boolean,
      default: false
    },
    allowBookingRequest: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapGetters([
      'isVehicleSelected',
      'selectedVehicle'
    ]),
    ...mapState([
      'selectedVehicles'
    ]),
    hasIndividualTerm() {
      return this.isVehicleSelected && this.selectedVehicle.term !== undefined
    },
    earliestStartDate() {
      const d = new Date().fp_incr(1)
      return d.toLocaleDateString('en-AU')
    },
    earliestEndDate() {
      const d = new Date().fp_incr(this.minRentalPeriod + 1)
      return d.toLocaleDateString('en-AU')
    },
    errorMessages() {
      return {
        required: this.labels.notEmpty,
        email: this.labels.notValidEmail,
        validEmail: this.labels.notValidEmail,
        sameAs: this.labels.mustAcceptTerms,
      }
    },
    stationsOptions() {
      if (this.inquiryMeta.handoverType === HANDOVER_TYPE.DEALER_DELIVERY && this.cdp)  {
        const station = this.stations.find(station => station.id === this.cdp)
        if (station) {
          return [station]
        }
      }

      return this.stations.filter(station =>
        this.inquiryMeta.handoverType === HANDOVER_TYPE.COLLECTION
          ? station.type === 'hertz'
          : station.type !== 'hertz'
      )
    },
    stationSurchargeNote() {
      if (!this.inquiry.rental.handoverStation || !this.inquiry.rental.handoverStation.surcharge) {
        return
      }
      const surcharge = this.inquiry.rental.handoverStation.surcharge
      const label = new String(this.labels.rentalCollectionSurchargeNote)
      const surchargeFormatted = surcharge.indexOf('%') === -1 ? `CHF ${surcharge}` : surcharge
      return label.replace('{n}', surchargeFormatted)
    },
    contactLanguageOptions() {
      return [
        { id: 'de', label: this.labels.languageGerman },
        { id: 'fr', label: this.labels.languageFrench },
        { id: 'it', label: this.labels.languageItalian },
        { id: 'en', label: this.labels.languageEnglish }
      ]
    }
  },
  data() {
    return {
      INQUIRY_TYPE,
      HANDOVER_TYPE,
      stations: [],
      state: 'form',
      minRentalPeriod: MIN_DEFAULT_RENTAL_PERIOD,
      fixedEndDate: 0,
      inquiryMeta: {
        // type: INQUIRY_TYPE.QUESTION
        type: INQUIRY_TYPE.OFFER,
        handoverType: HANDOVER_TYPE.COLLECTION,
        isDeliveryWithDifferentAddress: false,
        isCallRequested: false,
        isDealer: this.group === 'dealers'
      },
      inquiry: {
        client: {
          firstName: '',
          lastName: '',
          company: '',
          phone: '',
          email: '',
          address: '',
          postalCode: '',
          city: ''
        },
        rental: {
          startDate: null,
          endDate: null,
          handoverStation: null,
          delivery: {
            address: '',
            postalCode: '',
            city: ''
          }
        },
        dealer: {
          cdp: '',
          company: this.dealerCompany ? this.dealerCompany : '',
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        },
        message: '',
        contactLanguage: null,
        contactDate: null,
        contactTime: null,
        newsletterHerold: false,
        newsletterBrand: false,
        termsAccepted: false
      },
      flatPickrConfig: {
        rentalDate: {
          dateFormat: 'd/m/Y',
          minDate: new Date().fp_incr(1),
          monthSelectorType: 'static',
          static: true,
          locale: {
            ...FlatPickrLanguages[LANGUAGE]
          }
        },
        rentalEndDate: {
          dateFormat: 'd/m/Y',
          minDate: new Date().fp_incr(1),
          monthSelectorType: 'static',
          static: true,
          locale: {
            ...FlatPickrLanguages[LANGUAGE]
          }
        },
        contactDate: {
          dateFormat: 'd/m/Y',
          minDate: new Date().fp_incr(1),
          monthSelectorType: 'static',
          static: true,
          locale: {
            ...FlatPickrLanguages[LANGUAGE]
          }
        },
        contactTime: {
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true,
          minuteIncrement: 30,
          minDate: '08:00',
          maxDate: '17:00',
          monthSelectorType: 'static',
          static: true,
          locale: {
            ...FlatPickrLanguages[LANGUAGE]
          }
        },
      }
    }
  },
  components: {
    IconMail,
    FlatPickr,
    TextField,
    Multiselect
  },
  mounted() {
    if(!this.allowBookingRequest) {
      this.inquiryMeta.type = INQUIRY_TYPE.QUESTION
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scrollTo({ top: this.$el.offsetTop, behavior: 'smooth' })
        return
      }

      const data = JSON.parse(JSON.stringify(this.inquiry))
      data[window.csrfTokenName] = window.csrfTokenValue
      data.inquiryType = this.inquiryMeta.type === INQUIRY_TYPE.OFFER ? 'offer' : 'question'
      data.contactLanguage = data.contactLanguage.id

      if (this.referralCode) {
        data.referralCode = this.referralCode
      }

      if (this.discountCode) {
        data.discountCode = this.discountCode
      }

      if (this.inquiryMeta.type === INQUIRY_TYPE.OFFER) {
        switch (this.inquiryMeta.handoverType) {
          case HANDOVER_TYPE.COLLECTION:
            data.rental.handoverType = 'collection'
            break
          case HANDOVER_TYPE.DEALER_DELIVERY:
            data.rental.handoverType = 'deliveryToDealer'
            data.rental.delivery.cdp = data.rental.handoverStation.id
            data.rental.delivery.address = data.rental.handoverStation.address
            data.rental.delivery.postalCode = data.rental.handoverStation.zipCode
            data.rental.delivery.city = data.rental.handoverStation.city
            break
          case HANDOVER_TYPE.HOME_DELIVERY:
            data.rental.handoverType = 'deliveryToCustomer'
            if (!this.inquiryMeta.isDeliveryWithDifferentAddress) {
              // NOT different delivery address, so set to customer address
              data.rental.delivery.address = data.client.address
              data.rental.delivery.postalCode = data.client.postalCode
              data.rental.delivery.city = data.client.city
            }
            break
        }

        if (data.rental.handoverStation) {
          data.rental.handoverStation = data.rental.handoverStation.title
        }

        const vehicles = []
        if (this.isVehicleSelected) {
          for (let i in this.selectedVehicles) {
            vehicles.push(JSON.parse(JSON.stringify(this.selectedVehicles[i])))
          }
        }
        data.rental.vehicles = vehicles

        tracking.pushEvent({
          event: this.trackingEventName,
          carType: vehicles.map(v => v.title).join(', '),
          start_date: data.rental.startDate,
          end_date: data.rental.endDate,
          more_offers: data.newsletterHerold
        })
      }

      data.source = {
        'language': LANGUAGE,
        'page': this.page,
        'clientId': tracking.getClientId() ? tracking.getClientId() : ''
      }

      this.state = 'submitting'

      axios({
        url: ACTION_URI,
        method: 'POST',
        data: data
      })
        .then(response => { // eslint-disable-line no-unused-vars
          this.state = 'thanks'
          if (!response.data || !response.data.success) {
            throw new Error()
          }
          Vue.nextTick().then(() => {
            const $a = document.querySelector(`a[name="inquiry"]`)
            window.scrollTo({ top: $a.offsetTop, behavior: 'smooth' })
          })
        })
        .catch(error => {
          console.log(error)
          this.state = 'error'
        })
    },
    onRentalDateChange([selectedDate]) {
      const minEndDate = new Date(selectedDate).fp_incr(this.minRentalPeriod)

      let currentEndDate
      if(this.hasIndividualTerm) {
        this.fixedEndDate = minEndDate.toLocaleDateString('en-AU')
      } else {
        Vue.set(this.flatPickrConfig.rentalEndDate, 'minDate', minEndDate)
        currentEndDate = this.$refs.rentalEndDate.fp.selectedDates.length ? this.$refs.rentalEndDate.fp.selectedDates[0] : false
      }

      if (!currentEndDate) {
        this.inquiry.rental.endDate = minEndDate.toLocaleDateString('en-AU')
      } else {
        // if end date too early: overwrite to earliest
        const oneMonth = this.minRentalPeriod * 24 * 60 * 60 * 1000
        const distance = Math.round(Math.abs((selectedDate.getTime() - currentEndDate.getTime()) / (oneMonth)))
        if (distance < 1) {
          this.inquiry.rental.endDate = minEndDate
        }
      }
    },
    onContactDateChange([selectedDate]) {
      const now = dayjs()
      const date = dayjs(selectedDate)
      let earliestContactTime
      if (date.isTomorrow()) {
        let earliestMinute = now.minute() < 30 ? 0 : 30
        let earliestHour = now.hour() + 1
        earliestContactTime = earliestHour + ':' + earliestMinute
      } else {
        earliestContactTime = '8:00'
      }

      Vue.set(this.flatPickrConfig.contactTime, 'minDate', earliestContactTime)
      this.inquiry.contactTime = null
    },
    stationLabel(station) {
      return `${station.title}, ${station.zipCode} ${station.city}`
    },
    vehicleTitle(selectedVehicle) {
      if (this.allowMultipleVehicles) {
        return selectedVehicle.title
      }

      return selectedVehicle.title.replace(/volvo|toyota|lexus/gi, '')
    },
    removeVehicle(vehicle) {
      this.$store.commit('removeVehicle', vehicle)
    },
  },
  created() {
    // Set dates
    if (!this.isMobile) {
      this.flatPickrConfig.contactDate.disable = [date => (date.getDay() == 0 || date.getDay() == 6)]
    }

    const now = dayjs()
    if (now.hour() >= 17) {
      let earliestContactDate = now.add(2, 'day')
      if (earliestContactDate.isoWeekday() > 5) {
        earliestContactDate = earliestContactDate.add(8 - earliestContactDate.isoWeekday() , 'day')
        this.flatPickrConfig.contactDate.minDate = earliestContactDate.toDate()
      }
    }

    // Set hidden data fields
    this.inquiry.contactLanguage = this.contactLanguageOptions.find(lang => lang.id === LANGUAGE)
    this.inquiry.dealer.cdp = this.cdp

    // Retrieve stations list
    axios.get('/api/stations.json')
      .then(response => {
        if (response.status >= 400 || !response.data) {
          throw new Error('Invalid response!')
        }

        this.stations = response.data.stations

        if (this.cdp) {
          this.inquiryMeta.handoverType = HANDOVER_TYPE.DEALER_DELIVERY
        }
      })
      .catch(error => {
        console.log('error', error)
      })
  },
  filters: {
    truncate(value) {
      const maxLength = 13
      return value.length > maxLength
        ? value.substring(0, maxLength) + '…'
        : value
    }
  },
  watch: {
    selectedVehicle() {
      if (this.hasIndividualTerm) {
        this.minRentalPeriod = this.selectedVehicle.term
        const newEndDate = new Date().fp_incr(this.minRentalPeriod + 1)
        this.fixedEndDate = newEndDate.toLocaleDateString('en-AU')
      } else {
        if(this.minRentalPeriod !== MIN_DEFAULT_RENTAL_PERIOD) {
          if(this.$refs.rentalEndDate) {
            this.$refs.rentalEndDate.fp.clear()
          }
          const newEndDate = new Date().fp_incr(MIN_DEFAULT_RENTAL_PERIOD + 1)
          Vue.set(this.flatPickrConfig.rentalEndDate, 'minDate', newEndDate )
        }
        this.minRentalPeriod = MIN_DEFAULT_RENTAL_PERIOD
      }
    },
    isVehicleSelected(isSelected, wasSelected) {
      this.inquiryMeta.type = isSelected ? INQUIRY_TYPE.OFFER : INQUIRY_TYPE.QUESTION
      this.$v.selectedVehicles.$touch()
    },
    'inquiryMeta.handoverType'() {
      this.inquiry.rental.handoverStation = null

      if (this.inquiryMeta.handoverType === HANDOVER_TYPE.DEALER_DELIVERY && this.cdp) {
        // Force handover station selection to logged-in dealership
        const station = this.stations.find(station => station.id === this.cdp)
        if (station) {
          this.inquiry.rental.handoverStation = station
        }
      }
    }
  }
}
</script>

<style>
.multiselect,
.multiselect__tags {
  min-height: 54px;
  font-size: 1em;
}

.multiselect__tags {
  padding: 13px 54px 0 13px;
  /* border: none; */
  border-radius: 0;
}

.multiselect__content-wrapper {
  border-radius: 0;
}

.multiselect--disabled .multiselect__select {
  background: transparent;
}

.multiselect__single,
.multiselect__input {
  min-height: 27px;
  line-height: 27px;
}

.multiselect__placeholder {
  padding-left: 5px;
}

.multiselect__select {
  height: 50px;
}

.multiselect__option {
  min-height: 54px;
  line-height: 28px;
  padding-left: 17px;
}
.spinner {
  @apply text-black;
  display: inline-block;
  width: 24px;
  height: 24px;
  animation: spin 0.5s infinite linear;
  border: 4px solid;
  border-right-color: transparent;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
